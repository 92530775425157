import App from './app/xs/app';
import initMenu from './menu/xs/menu';

import initFilerpicker from './filepicker/xs/filepicker';
import initHeader from './header/xs/header';
import initPartners from './partners/xs/partners';
import initMap from './map/xs/map';
import { applyPhoneMasks, initFormTimers, initFloatForms } from './form/xs/form';
import { initVideoReviews, initTextSliderReviews } from './reviews/xs/reviews';
import initLetters from './letters/xs/letters';
import initInfo from './info/xs/info';
import { search } from './search/xs/search';
import initSlider, { sliderService } from './slider/xs/slider';
import initHeadline from './headline/xs/headline';
import { expandable } from './text/xs/text';
import history from './history/xs/history';

// App entry point

search.init();
expandable.init();
App.initSpamCheck();
App.initVideos();
App.initScrollLinks();
App.initVkGroup();
history.init();

initMenu();
initFilerpicker();
initHeader();
initPartners();
initMap();
applyPhoneMasks();
initVideoReviews();
initTextSliderReviews();
initLetters();
initInfo();
initSlider();
initHeadline();
initFormTimers();
initFloatForms();

if ($('[data-role="slider-service"]').length > 0) {
    sliderService.init();
}

window.addEventListener('load', () => {
    App.initLazyLoad();
});

window._scrollTo = function (id) {
    document.querySelector(id).scrollIntoView({ behavior: 'smooth' });
};