export default function initInfo() 
{ 
    $('[data-role="info-trigger"]').on('click', function() {

        let self = $(this),
            thisContent = self.next(),
            thisBtn = self.find('[data-role="info-button"]');

        thisBtn.toggleClass('active');
        thisContent.slideToggle();
    });
}