export function applyPhoneMasks() {
    if ($('[data-phone]').length > 0) {

        let inputs = document.querySelectorAll('[data-phone]');

        for (var i = 0; i < inputs.length; i++) {
            let mask = new Inputmask("+7 (999) 999-99-99");
            let phoneInput = inputs[i];
            mask.mask(phoneInput);
        }
    }
}

export function initFloatForms() {
    $('[data-role="form-trigger"]').on('click', function(e) {
        e.preventDefault();

        let target = $(this).data('target');

        $.fancybox.open({
            src: `#${target}`,
            touch: false
        });
    });
}


class FormTimer {
    constructor(config) {

        this.baseElement = config.element;

        let time = parseInt(this.baseElement.data('end'), 10),
            counters = this.baseElement.find('[data-role="timer-counter"]'),
            names = this.baseElement.find('[data-role="timer-name"]');

        this.timeEnd = time * 1000;
        this.endDate = new Date(this.timeEnd);

        this.daysLeft = this.getDaysBetween();

        this.DOM = {
            first: {
                counter: counters[0],
                name: names[0]
            },
            second: {
                counter: counters[1],
                name: names[1]
            },
            third: {
                counter: counters[2],
                name: names[2]
            }
        };

        this.timer = null;

    }

    run() {

        this.update();

        this.timer = setInterval(() => {

            this.update();

        }, 1000);

        this.showItems();
    }

    showItems() {
        this.baseElement.find('.form-timer-item').addClass('active');
    }

    update() {

        if (this.timeEnd < Date.now()) {
            this.baseElement.html('<div class="form__timer-stub">Регистрация окончена</div>');

            clearInterval(this.timer);
            this.timer = null;

            return false;
        }

        this.daysLeft = this.getDaysBetween();
        this.hoursLeft = this.getHoursLeft();

        let hoursLeftInDay = this.getHoursLeftInDay(),
            minutesLeftInHour = this.getMinutesLeftInHour(),
            secondsLeftInMinute = this.getSecondsLeftInMinute();

        this.DOM.first.counter.textContent = this.daysLeft >= 2 ? this.daysLeft : this.hoursLeft;
        this.DOM.first.name.textContent = this.daysLeft >= 2 ? this.getType('days', this.daysLeft) : this.getType('hours', this.hoursLeft);

        this.DOM.second.counter.textContent = this.daysLeft >= 2 ? hoursLeftInDay : minutesLeftInHour;
        this.DOM.second.name.textContent = this.daysLeft >= 2 ? this.getType('hours', hoursLeftInDay) : this.getType('minutes', minutesLeftInHour);

        this.DOM.third.counter.textContent = this.daysLeft >= 2 ? minutesLeftInHour : secondsLeftInMinute;
        this.DOM.third.name.textContent = this.daysLeft >= 2 ? this.getType('minutes', minutesLeftInHour) : this.getType('seconds', secondsLeftInMinute);
    }

    getDaysBetween() {
        //Get 1 day in milliseconds
        let one_day = 1000 * 60 * 60 * 24;

        let difference_ms = this.timeEnd - Date.now();

        // Convert back to days and return
        return Math.round(difference_ms / one_day);
    }

    getHoursLeft() {
        let oneHour = 1000 * 60 * 60;

        let difference_ms = this.timeEnd - Date.now();

        return Math.round(difference_ms / oneHour);
    }

    getHoursLeftInDay() {
        return 24 - new Date().getHours() - 1;
    }

    getMinutesLeftInHour() {
        return 60 - new Date().getMinutes();
    }

    getSecondsLeftInMinute() {
        return 60 - new Date().getSeconds();
    }

    getType(type, number) {
        const types = {
            days: {
                1: 'день',
                2: 'дня',
                3: 'дней'
            },
            hours: {
                1: 'час',
                2: 'часа',
                3: 'часов'
            },
            minutes: {
                1: 'минута',
                2: 'минуты',
                3: 'минут'
            },
            seconds: {
                1: 'секунда',
                2: 'секунды',
                3: 'секунд'
            }
        }

        if (number == 1 || (number > 20 && number % 10 === 1)) {
            return types[type][1];
        } else if ((number >= 2 && number <= 4) || (number > 20 && (number % 10 >= 2 && number % 10 <= 4))) {
            return types[type][2];
        } else {
            return types[type][3];
        }
    }
}

export function initFormTimers() {

    if ($('[data-role="form-timer"]').length <= 0) return false;

    $('[data-role="form-timer"]').each((index, item) => {
        let timer = new FormTimer({
            element: $(item)
        });

        timer.run();
    });

}