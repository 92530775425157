export default function initHeadline() 
{ 
    $('[data-role="headline-scroll"]').on('click', function () {
        const target = $(this).data('target'),
              targetElem = $(`#${target}`);

        if (targetElem.length <= 0) {
            alert('Please insert target elem on the page');
            return false;
        }

        const distance = targetElem.offset().top;

        $('html, body').animate({
            scrollTop: distance
        }, 1500);
    });
}