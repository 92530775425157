class Expandable {
    init() {
        this.checkHeight();
        this.listen();
    }

    checkHeight() {
        if($('[data-role="expandable"]').height()>100) {
            $('.description__actions').show();
        } else {
            $('[data-role="expandable"]').toggleClass('text_nonexpandable');
            $('.description__actions').hide();
        }
    }

    listen() {
        $('[data-role="expandable-trigger"]').on('click', this.toggle);
    }   

    toggle (e) {
        let btn = $(this),  
            parent = btn.parents('[data-role="expandable-wrapper"]'),
            span = btn.find('span'), 
            text = parent.find('[data-role="expandable"]');

        if (btn.hasClass('active')) {
            span.text('Читать полное описание');
        } else {
            span.text('Свернуть');
        }

        btn.toggleClass('active');
        text.toggleClass('active');
    }
}

const expandable = new Expandable();

export { expandable };