export function initVideoReviews()
{
    function fancyboxVideo(url){
        $.fancybox.open({
            src : url,
            type : 'iframe',
            opts : {
                iframe : {
                    attr : {
                        scrolling : 'no',
                        autoplay: true,
                        allow: 'autoplay',
                        mute: 1,
                        rel : 0,
                    }
                }
            }
        });
    }

    $('[data-video]').on('click', function () {
        let videoUrl  = $(this).data('video');
        fancyboxVideo(videoUrl);
    });
}

export function initTextSliderReviews()
{
    $('[data-role="reviews-text-slider"]').slick({
        arrows: true,
        prevArrow: '<div class="reviews__arrow reviews__arrow_prev"></div>',
        nextArrow: '<div class="reviews__arrow reviews__arrow_next"></div>',
        slidesToShow: 2,
        slidesToScroll: 1,
        touchThreshold: 30,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 990,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
        ]
    });

    $('[data-role="reviews-service-slider"]').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        // variableWidth: true,
        // centerMode: true,
        dots: true,
        fade: true,
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 990,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
                centerMode: false,
              }
            }
        ]
    });
}