export default function initFilepicker() 
{   

    $('[data-role="filepicker"] input[type="file"]').on('change', function () {
        let file = this.files[0],
            fileContainer = $(this).parent().find('[data-role="filepicker-file"]'),
            filenameContainer = fileContainer.find('span');

        if (typeof file !== 'undefined') {
            filenameContainer.text(file.name);
            fileContainer.show();
        } else {
            filenameContainer.text('');
            fileContainer.hide();
        }
    });

    $('[data-role="filepicker-clear"]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        let thisFilepicker = $(this).parents('[data-role="filepicker"]'),
            thisInput = thisFilepicker.find('input[type="file"]'),
            thisFilecontainer = thisFilepicker.find('[data-role="filepicker-file"]'),
            thisFilename = thisFilecontainer.find('span');
        
        thisInput.val('');
        thisFilecontainer.hide();
        thisFilename.text('');

    });
}