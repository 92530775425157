export default function initMap() {
    if (typeof ymaps === "undefined" || $('#map').length <= 0) return;

    function init() {
        var myMap = new ymaps.Map("map", {
            center: [58.627955, 49.640202],
            controls: ['zoomControl', 'fullscreenControl'],
            zoom: 15
        });

        myMap.behaviors.disable('scrollZoom');

        let placemark = new ymaps.Placemark(
            [58.631110, 49.641010], {
                balloonContentHeader: "ООО «КРЦ «Охрана труда»",
                balloonContentBody: "Октябрьский пр-т, д. 22а"
            }, {
                iconLayout: 'default#imageWithContent',
                iconImageHref: '/statics/images/map-placemark-primary.svg',
                iconImageSize: [48, 48],
                iconImageOffset: [-24, -48],
                iconContentOffset: [0, 0],
            }
        );

        let placemark2 = new ymaps.Placemark(
            [58.631110, 49.641010], {
                balloonContentHeader: "ЧОУ ДПО «Учебный центр «Охрана труда»",
                balloonContentBody: "Октябрьский пр-т, д. 22а"
            }, {
                iconLayout: 'default#imageWithContent',
                iconImageHref: '/statics/images/map-placemark-secondary.svg',
                iconImageSize: [48, 48],
                iconImageOffset: [-20, -48],
                iconContentOffset: [0, 0],
            }
        );

        myMap.geoObjects.add(placemark);
        myMap.geoObjects.add(placemark2);

        var placemarks = {
            placemark1: placemark,
            placemark2,
        };

        $('[data-place]').on('click', function() {

            let mapPosition = $("#map").offset().top,
                placemark = $(this).data('place');

            $('html, body').animate({
                scrollTop: mapPosition
            }, 1000);
            setTimeout(() => {
                placemarks[placemark].balloon.open();
            }, 1000);

        });
    }

    ymaps.ready(init);
}