/**
 * Main app component
 * Utilities and usefull functions
 */
const App = (function() {

    const SMALL_DEVICE = 1024; // Less than bootstrap LG breakpoint

    /**
     * 
     * Public methods goes here
     * 
     * */
    return {

        // Detects if device is touch
        is_touch: /(iphone|ipod|ipad|android|iemobile|blackberry|bada)/.test(window.navigator.userAgent.toLowerCase()),

        // Detects browser is IE11
        is_ie11: !!window.MSInputMethodContext && !!document.documentMode,

        // Detects if device is MAC
        is_mac: navigator.platform.toLowerCase().indexOf('mac') >= 0,

        // Detects if device is Andoroid
        is_android: /(android)/.test(window.navigator.userAgent.toLowerCase()),

        // Detects if device is small - Less than bootstrap LG breakpoint
        is_small_device: $(window).width() < SMALL_DEVICE,

        /**
         * Detects if device is mobile
         * @returns {Boolean}
         */
        is_mobile: function() {
            return this.is_small_device;
        },

        initSpamCheck: function() {
            $('[data-role="spamcheck"]').val('me5Ec75f');
        },

        initVideos: function() {

            $('[data-role="embeded-video"]').on('click', function() {
                var videoSrc = $(this).data('src');

                if (!videoSrc) return;

                var iframe = document.createElement("iframe");


                iframe.setAttribute("frameborder", "0");
                iframe.setAttribute("allowfullscreen", "");
                iframe.setAttribute("webkitallowfullscreen", "");
                iframe.setAttribute("mozallowfullscreen", "");
                iframe.setAttribute("allowfullscreen", "");
                iframe.setAttribute("allowtransparency", "true");
                iframe.setAttribute("autoplay", "autoplay");
                iframe.setAttribute("allow", "autoplay");
                iframe.setAttribute("mute", "1");
                iframe.setAttribute("rel", "0");
                this.innerHTML = "";
                this.appendChild(iframe);

                iframe.setAttribute("src", videoSrc + "?rel=0&showinfo=0&autoplay=true");

                this.classList.add('app__video_active');
                $(this).addClass('slider__item_video_active');
            });
        },

        initLazyLoad() {
            let lazyLoadImages = $('[data-lazyload]');

            if (lazyLoadImages.length < 0) return false;

            lazyLoadImages.each((index, item) => {
                let elem = $(item),
                    src = elem.data('lazyload'),
                    type = elem.data('lazy-type');

                if (type === 'background') {

                    elem[0].removeAttribute('style');
                    elem.css({
                        'background-image': `url(${src})`
                    });

                } else if (type === 'img') {
                    elem.attr('src', src);
                }
            });
        },

        initScrollLinks() {
            let scrollLinks = $('[data-scroll]');

            if (scrollLinks.length <= 0) return false;

            scrollLinks.on('click', function(e) {
                e.preventDefault();

                let target = $(this).attr('href'),
                    targetElem = $(target);

                if (targetElem.length <= 0) return false;

                $('html, body').animate({
                    scrollTop: targetElem.offset().top
                }, 700);

            });
        },

        getInternetExplorerVersion() {
            let rv = -1;
            if (navigator.appName == 'Microsoft Internet Explorer')
            {
                let ua = navigator.userAgent;
                let re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null)
                    rv = parseFloat( RegExp.$1 );
            }
            else if (navigator.appName == 'Netscape')
            {
                let ua = navigator.userAgent;
                let re  = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null)
                    rv = parseFloat( RegExp.$1 );
            }
            return rv;
        },

        initVkGroup() {
            let vkGroups = $('#vk_groups');

            if (vkGroups.length <= 0 || this.getInternetExplorerVersion()!==-1) return false;

            VK.Widgets.Group("vk_groups", {mode: 3, width: 'auto'}, 57079344);
        }
    };

})();

export default App;