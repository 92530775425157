const Nav = (function () {

    const ref = $('[data-role="nav"]'),
          dropdownButtons = $('[data-role="nav-dropdown-button"]');

    return {
        toggle() {
            ref.toggleClass('active');
            $('body').toggleClass('fixed');
        },

        initDropdowns() {
            dropdownButtons.on('click', function () {
                
                let btnRef = $(this),   
                    thisItem = btnRef.parents('[data-role="nav-dropdown-item"]'),
                    dropdown = thisItem.find('[data-role="nav-dropdown-list"]');

                dropdown.slideToggle();
                btnRef.toggleClass('active');

            });
        }
    };
})();

Nav.initDropdowns();

export default Nav;