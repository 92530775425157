import App from '../../app/xs/app';

class History {

    constructor() {
        this.sliders = null;
        this.slickInstance = null;
        this.btn = null;
        this.content = $('.history__inner');
    }

    init() {
        if ($('[data-role="history"]').length <= 0) return false;

        this.sliders = $('[data-role="history-slider"]');
        this.slidersV = $('[data-role="history-slider-vertical"]');
        this.btn = $('[data-role="history-more-button"]');

        this.initSliders();

        this.btn.on('click', () => {
            if (this.content.hasClass('active')) {
                this.content.removeClass('active');
                this.btn.text('Смотреть еще');

                $([document.documentElement, document.body]).animate({
                    scrollTop: this.btn.offset().top - 100
                }, 100);

            } else {
                this.content.addClass('active');
                this.btn.text('Свернуть');
            }
        });
    }

    initSliders() {

        this.slickInstance = this.sliders.slick({
            variableWidth: true,
            arrows: false,
            infinite: true,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    variableWidth: false
                  }
                }
            ]
        });

        this.slickInstance = this.slidersV.slick({
            variableWidth: true,
            arrows: false,
            infinite: true
        });

        let scrollCount = null;
        let scroll = null;

        this.slickInstance.on('wheel', (function(e) {
            e.preventDefault();
          
            scroll = setTimeout(function(){scrollCount=0;}, 200);

            if(scrollCount) return 0;

            scrollCount = 1;
          
            if (e.originalEvent.deltaY < 0) {
                $(this).slick('slickPrev');
            } else {
                $(this).slick('slickNext');
            }
        }));
        
    }
}

const history = new History();

export default history;