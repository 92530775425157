export default function initSearch() 
{ 
 //Module code goes here 
}

class Search {

    constructor() {
        this.input = $('[data-role="search-input"]');
        this.searchContainer = $('[data-role="search-top"]');
        this.initialWidth = this.input.width();
        this.timer = null;
        this.active = false;
    }

    init() {
        this.initHandlers();
    }

    initHandlers() {
        this.input.on('focusin', this.expand.bind(this));
        this.input.on('focusout', this.deactivate.bind(this));
        this.input.on('mouseout', this.resolveMouseOut.bind(this));
    }

    expand () {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.searchContainer.animate({
            'width': this.getMaxSearchWidth()
        }, 200);

        this.active = true;
    }

    deactivate() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.searchContainer.animate({
            'width': this.initialWidth
        }, 200);   

        this.active = false;        
    }

    resolveMouseOut(e) {
        if (
            !e.relatedTarget ||
            e.relatedTarget.className === 'search-top__button' ||
            this.input.val().trim() !== '' ||
            false === this.active
        ) return false;

        this.timer = setTimeout(() => {
            this.deactivate();
        }, 4000);
    }

    getMaxSearchWidth() {
        return $(window).width() <= 1200 ? 300 : 383;
    }
}

const search = new Search();

export { search };