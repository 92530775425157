import Nav from '../../nav/xs/nav';


export default function initHeader()
{

    $('[data-role="header-mobile-button"]').on('click', function () {
        $(this).toggleClass('active');

        Nav.toggle();
    });

    var headerForm = $('[data-role="header-mobile-form"]');

    $('[data-role="header-mobile-search"]').on('click', function () {
        headerForm.css('transform', 'translateY(0)');
    });

    $('[data-role="header-mobile-back"]').on('click', function () {
        headerForm.css('transform', 'translateY(-100%)');
    });

}