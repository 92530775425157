import App from '../../app/xs/app';

export default function initSlider() {

    function fixVerticalArrows(){
		var h = ($('.slick-active').find("img").height()/2);
        $('.slick-arrow').css('top',h+'px');
    }

	let slider;

    if(App.is_mobile()) {
        slider = $('[data-role="card-slider"]').slick({
            dots: true,
            infinite: false,
            adaptiveHeight: true
        }).on('afterChange',function(event){
            fixVerticalArrows();
        }).trigger('afterChange');

        $(window).resize(function(){
            fixVerticalArrows();
        });
    } else {
        slider = $('[data-role="card-slider"]').slick({
            dots: true,
            infinite: false,
            adaptiveHeight: true
        });
    }

    let dots = $('.slick-dots');
    let dotsli = slider.find('.slick-dots li');

    if (dotsli.length <= 1) dots.css({ 'display': 'none' });
}

class SliderService {

    constructor() {
        this.btn = null;
        this.wrapper = null;
        this.items = null;
    }

    init() {
        this.btn = $('[data-role="slider-service-more"]');
        this.wrapper = $('[data-role="slider-service"]');
        this.items = $('[data-role="slider-service-item"]');

        if (App.is_mobile()) {
            this.initPartitialLoading();
        } else {
            this.initSlickSlider();
        }
    }

    initSlickSlider() {
        this.wrapper.slick({
            slidesToShow: 3,
            responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 990,
                    settings: "unslick"
                }
            ]
        });
    }

    initPartitialLoading() {

        this.btn.on('click', () => {

            if (this.btn.hasClass('active')) {
                this.btn.find('span').text('Больше фото');
            } else {
                this.btn.find('span').text('Свернуть');
            }

            this.btn.toggleClass('active');
            this.items.toggleClass('active');

        });
    }

}

const sliderService = new SliderService();

export { sliderService };